import React from 'react';

// Components
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import { Container, BlogPostGrid } from '../styles/globalStyles';
import Button from '../components/button';
import styled from '@emotion/styled';
import { HiOutlineArchive } from 'react-icons/hi';
import { IoPricetagsOutline } from 'react-icons/io5';

const ButtonContainer = styled.nav`
  display: grid;
  grid-template-columns: 100%;
  @media (min-width: 768px) {
    grid-template-columns: 150px 150px 1fr;
  }
  gap: 16px;

  a {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    max-width: none;
    @media (min-width: 768px) {
      max-width: 150px;
    }
  }

  svg {
    margin-right: 8px;
  }
`;

const TagsList = styled.ol`
  padding-left: 2rem;
`;

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext;
  const { edges, totalCount } = data.allMdx;
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? '' : 's'
  } tagged with "${tag}"`;

  return (
    <Layout>
      <Container>
        <BlogPostGrid>
          <h1>{tagHeader}</h1>
          <p></p>
          <ButtonContainer>
            <Button to="/tags">
              <IoPricetagsOutline />
              All tags
            </Button>
            <Button to="/blog">
              <HiOutlineArchive />
              All posts
            </Button>
          </ButtonContainer>
          <p></p>
          <TagsList>
            {edges.map(({ node }) => {
              const { title, slug } = node.frontmatter;
              return (
                <li key={slug}>
                  <Link to={`/blog/${slug}`}>{title}</Link>
                </li>
              );
            })}
          </TagsList>
          <p></p>
        </BlogPostGrid>
      </Container>
    </Layout>
  );
};

export default Tags;

export const pageQuery = graphql`
  query ($tag: String) {
    allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            slug
          }
        }
      }
    }
  }
`;
